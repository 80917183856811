/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { formatToBRL } from "brazilian-values";

import trash_icon from "../../assets/icons/trash_icon.svg";
import cart_icon from "../../assets/icons/cart_icon.svg";

import "./styles.scss";

function copyObjArr(objOrArr) {
  return JSON.parse(JSON.stringify(objOrArr));
}

export function PlansChooser({ added, notAdded, onChange }) {
  const [itemsAdded, setItemsAdded] = useState(copyObjArr(added) || []);
  const [itemsNotAdded, setItemsNotAdded] = useState(
    copyObjArr(notAdded) || [],
  );
  const [itemsIncluded, setItemsIncluded] = useState({});
  const [itemsExcluded, setItemsExcluded] = useState({});
  const [isKmPopupOpen, setIsKmPopupOpen] = useState(false);
  const [kmOptions, setKmOptions] = useState([]);
  const [kmIndex, setKmIndex] = useState(-1);

  function handleChange() {
    const included = itemsAdded.filter((i) => {
      if (i.$isReboque) {
        /* console.log("sevn", i); */
      }

      if (i.$isReboque && i.$initialPrice !== i.$newPrice) {
        return true;
      }

      return (
        added.findIndex((a) => {
          return a.service_name === i.service_name;
        }) === -1
      );
    });

    const excluded = itemsNotAdded.filter(
      (i) =>
        notAdded.findIndex((a) => a.service_name === i.service_name) === -1,
    );
    if (onChange) {
      const output = {
        added: itemsAdded,
        notAdded: itemsNotAdded,
        includedInPlan: included,
        excludedFromPlan: excluded,
        amountIncluded: included.reduce((total, i) => {
          if (i.$isReboque) {
            return total + i.$newPrice - i.$initialPrice;
          }
          return total + i.value;
        }, 0),
        amountExcluded:
          excluded.reduce((total, i) => {
            if (i.$isReboque) {
              return total + i.$initialPrice;
            }
            return total + i.value;
          }, 0) * -1,
      };

      onChange(output);
    }
  }

  function handleAdd(itemIndex, e) {
    e.preventDefault();

    setItemsAdded([...itemsAdded, itemsNotAdded[itemIndex]]);

    delete itemsExcluded[itemsNotAdded[itemIndex].service_code];
    itemsIncluded[itemsNotAdded[itemIndex].service_code] =
      itemsNotAdded[itemIndex].value;
    setItemsIncluded({ ...itemsIncluded });
    itemsNotAdded.splice(itemIndex, 1);
    setItemsNotAdded([...itemsNotAdded]);
  }

  function handleRemove(itemIndex, e, isReboque = false) {
    e.preventDefault();

    delete itemsAdded[itemIndex].$selectedKmFromContract;

    setItemsNotAdded([...itemsNotAdded, itemsAdded[itemIndex]]);

    delete itemsIncluded[itemsAdded[itemIndex].service_code];
    itemsExcluded[itemsAdded[itemIndex].service_code] =
      itemsAdded[itemIndex].value;
    setItemsExcluded({ ...itemsIncluded });

    itemsAdded.splice(itemIndex, 1);
    setItemsAdded([...itemsAdded]);
  }

  function handleChooseKm(e, kmOptionsList, index) {
    e.preventDefault();
    setKmOptions(kmOptionsList);
    setKmIndex(index);
    handleOpenKmPopup();
  }

  function handleOpenKmPopup() {
    setIsKmPopupOpen(true);
    document.body.style.overflow = "hidden";
  }

  function handleCloseKmPopup() {
    kmOptions.forEach((option) => (option.$selected = false));
    setIsKmPopupOpen(false);
    document.body.style.overflow = "auto";
  }

  function handleSelectKmOption(index) {
    kmOptions.forEach((option) => (option.$selected = false));
    kmOptions[index].$selected = true;
    setKmOptions([...kmOptions]);
  }

  function handleUpdateKm(e) {
    e.preventDefault();

    const itemIndex = itemsNotAdded.findIndex((i) => i.$isReboque);

    if (itemIndex === -1) {
      console.error(
        "Erro: não encontrou item de KM na lista. Cheque código-fonte.",
      );
      return;
    }

    const selectedKmOption = kmOptions.find((i) => i.$selected);

    itemsNotAdded[itemIndex] = {
      ...itemsNotAdded[itemIndex],
      $newPrice: selectedKmOption.preco,
      $diferentialPrice:
        0 - itemsNotAdded[itemIndex].value + selectedKmOption.preco,
      $initialPrice: itemsNotAdded[itemIndex].value,
      $selectedKmFromContract: {
        baseItem: "SRV_REBOQ",
        lineId: selectedKmOption.idLinha,
        itemCode: selectedKmOption.codigoItem,
        itemName: selectedKmOption.descricao,
        docDate: selectedKmOption.dataVinculo,
        price: selectedKmOption.preco,
      },
    };

    setItemsNotAdded([...itemsNotAdded]);

    handleCloseKmPopup();
    handleAdd(kmIndex, e);
  }

  useEffect(() => {
    handleChange();
  }, [itemsAdded, itemsNotAdded, itemsExcluded, itemsIncluded]);

  useEffect(() => {
    setItemsNotAdded(copyObjArr(notAdded));
  }, [notAdded]);

  useEffect(() => {
    setItemsAdded(copyObjArr(added));
  }, [added]);

  return (
    <>
      <div className="c_plans_chooser">
        {itemsAdded.length > 0 && <h4>Adquirido</h4>}

        <ul className="items">
          {itemsAdded.map((item, i) => (
            <>
              {!item.$isReboque && (
                <li className="items__item" key={item.service_name}>
                  <p className="items__item__title">{item.service_name}</p>
                  <p className="items__item__value">
                    {formatToBRL(item.value)} / Mês
                  </p>
                  <button
                    className={clsx({
                      items__item__button: true,
                      "items__item__button--remove": true,
                    })}
                    onClick={(e) => handleRemove(i, e)}
                  >
                    <img src={trash_icon} alt="Remover" />
                    <span>Remover</span>
                  </button>
                </li>
              )}
              {item.$isReboque && (
                <li className="items__item" key={item.service_name}>
                  {item.$selectedKmFromContract && (
                    <>
                      <p className="items__item__title">
                        {item.$selectedKmFromContract.itemName} na assistência
                      </p>
                      <p className="items__item__value">
                        {formatToBRL(item.$selectedKmFromContract.price)} / Mês
                      </p>
                    </>
                  )}

                  {!item.$selectedKmFromContract && (
                    <>
                      <p className="items__item__title">{item.service_name}</p>
                      <p className="items__item__value">
                        Necessário Definir Km
                      </p>
                    </>
                  )}

                  <button
                    className={clsx({
                      items__item__button: true,
                      "items__item__button--remove": true,
                    })}
                    onClick={(e) => handleRemove(i, e, true)}
                  >
                    <img src={trash_icon} alt="Remover" />
                    <span>Remover</span>
                  </button>
                </li>
              )}
            </>
          ))}
        </ul>

        <h4>Disponível</h4>

        <ul className="items">
          {itemsNotAdded.map((item, i) => (
            <>
              {!item.$isReboque && (
                <li className="items__item">
                  <p className="items__item__title">{item.service_name}</p>
                  <p className="items__item__value">
                    {formatToBRL(item.value)} / Mês
                  </p>
                  <button
                    className={clsx({
                      items__item__button: true,
                      "items__item__button--add": true,
                    })}
                    onClick={(e) => handleAdd(i, e)}
                  >
                    <img src={cart_icon} alt="Adicionar" />
                    <span>Adicionar</span>
                  </button>
                </li>
              )}
              {item.$isReboque && (
                <li className="items__item" key={item.service_name}>
                  {item.$selectedKmFromContract && (
                    <>
                      <p className="items__item__title">
                        {item.$selectedKmFromContract.itemName} na assistência
                      </p>
                      <p className="items__item__value">
                        {formatToBRL(item.$selectedKmFromContract.price)} / Mês
                      </p>
                    </>
                  )}

                  {!item.$selectedKmFromContract && (
                    <>
                      <p className="items__item__title">{item.service_name}</p>
                      <p className="items__item__value">
                        Necessário Definir Km
                      </p>
                    </>
                  )}

                  <button
                    className={clsx({
                      items__item__button: true,
                      "items__item__button--add": true,
                    })}
                    onClick={(e) => handleChooseKm(e, item.$kmOptions, i)}
                  >
                    <img src={cart_icon} alt="Adicionar" />
                    <span>Adicionar</span>
                  </button>
                </li>
              )}
            </>
          ))}
        </ul>

        {isKmPopupOpen && (
          <>
            <div className="popup_overlay">
              <div className="popup">
                <h1>
                  Alterar Quilometragem
                  <br />
                  na assistência
                  <button
                    class="popup__close_btn"
                    onClick={(e) => handleCloseKmPopup(e)}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z"
                        fill="#171A47"
                      />
                    </svg>
                  </button>
                </h1>
                <p>
                  Defina a quantidade de quilometragem você deseja para sua
                  assistência.
                </p>

                <ul className="options">
                  {kmOptions.map((option, i) => (
                    <>
                      <li
                        key={option.codigoItem}
                        className={clsx({
                          options__option: true,
                          "options__option--selected": option.$selected,
                        })}
                        onClick={() => handleSelectKmOption(i)}
                      >
                        <div
                          className={clsx({
                            options__option__ball: true,
                            "options__option__ball--selected": option.$selected,
                          })}
                        />
                        <span
                          className={clsx({
                            options__option__label: true,
                            "options__option__label--selected":
                              option.$selected,
                          })}
                        >
                          {option.descricao}
                        </span>
                        <span
                          className={clsx({
                            options__option__value: true,
                            "options__option__value--selected":
                              option.$selected,
                          })}
                        >
                          +{formatToBRL(option.preco)}
                        </span>
                      </li>
                    </>
                  ))}
                </ul>

                <button className="btn_add" onClick={(e) => handleUpdateKm(e)}>
                  Adicionar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
