import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { FirstScreen } from './firstScreen';
import { AttachmentCaptureScreen } from './attachmentCaptureScreen';
import cnhImage from "../../assets/images/cnh-img.png"
import crlvImage from "../../assets/images/crlv-image.png"
import reportCardImage from "../../assets/images/report-card.png"
import carPlateFrontImage from "../../assets/images/car-plate-front.png"
import carPlateBackImage from "../../assets/images/car-plate-back.png"
import carDamege1Image from "../../assets/images/car-damage-photo1.png"
import carDamege2Image from "../../assets/images/car-damage-photo2.png"
import {  api_auth, api_keyed, URLS } from '../../helpers/Request';
import Lockr from "lockr";
import { LoaderInline } from '../LoaderInline';


export function SinisterStep2 ({chamado, status}){
    const [currentStep, setCurrentStep] = useState("0")
    const [selectedFile, setSelectedFile] = useState("");   
    const [apiPhotos, setApiPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const clientData = Lockr.get("ClientData");
  
    const handleNextStep =()=>{
        setCurrentStep(`${parseInt(currentStep) + 1}`)

        if(currentStep === "7"){
            window.location.href = `/sinistros/consulta/${chamado}`
        }
    }

    async function handleUpload(details) {
        try {
            if (!selectedFile) {
                return;
            }
             
            setIsLoading(true);
            const fileType = selectedFile.includes("/pdf;") ? "pdf" : "jpg";

             const result = await api_auth
                .put(
                    `${URLS.FOTOS}/upload/${clientData.customerSelectedCar.contractInfo.nCtrId}/${details.TipoFoto.Id}/${fileType}/${chamado}`,
                {
                    body: selectedFile.split(",")[1],
            }).finally();
            console.log(result)

            if (!result.ok) {
                window.alert("Erro ao fazer upload de foto. Tente novamente.");
            }
            setSelectedFile("")
            handleNextStep()
          
        } catch (err) {
            console.error("Error ao enviar foto!", err)
            console.error("Error ao enviar foto!", err.message)
            window.alert("Erro ao fazer upload de foto. Tente novamente.");
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        async function loadData() {
            try{
                setIsLoading(true);
                const result = await api_auth
                  .get(
                    `${URLS.FOTOS}/${clientData.customerSelectedCar.contractInfo.nCtrId}/${chamado}`
                  )
                  .json();

                setApiPhotos(result || []);

                setIsLoading(false);
            }catch(err){
                console.error("Error ao buscar tipos de foto para o caso!", err.message)
            }
        }

        loadData()

        window.document.querySelector("body").style.paddingBottom = "10px"
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const Component = ()=>{ 
         switch(currentStep){
            case "0":
               return <React.Fragment>
                    <FirstScreen 
                        setCurrentStep={setCurrentStep} 
                        status={status} 
                        details={apiPhotos.find(element => element.TipoFoto.Id === "orcamento")}
                    />
                </React.Fragment>
            case "1":
                return <React.Fragment>
                    <AttachmentCaptureScreen 
                    title="CNH aberta" 
                    stepNumber="1" 
                    img={cnhImage}
                    orientation={[
                        "Posicione o documento dentro da área demarcada.",
                        "Importante estar com todos os campos visíveis."
                    ]}
                    handleUpload={handleUpload}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "cnh")}
                    handleNextStep={handleNextStep}
                    
                    />
                 </React.Fragment>
            case "2":
                return <React.Fragment>
                    <AttachmentCaptureScreen 
                    title="CRLV do automóvel" 
                    stepNumber="2" 
                    img={crlvImage}
                    orientation={[
                        "Posicione o documento dentro da área demarcada.",
                        "Importante estar com todos os campos visíveis."
                    ]}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "clrv")}
                    handleNextStep={handleNextStep}
                    handleUpload={handleUpload}
                    
                    />
                    
                </React.Fragment>
            case "3":
                return <React.Fragment>
                <AttachmentCaptureScreen 
                    title="Boletim de ocorrência completo" 
                    stepNumber="3" 
                    img={reportCardImage}
                    orientation={[
                        "Posicione o documento dentro da área demarcada.",
                        "Importante estar com todos os campos visíveis."
                    ]}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "boletim")}
                    handleNextStep={handleNextStep}
                    handleUpload={handleUpload}
                    isAttach={true}
                    isPhoto={false}
                />
            </React.Fragment>
            case "4":
                return <React.Fragment>
                    <AttachmentCaptureScreen 
                    title="Foto dianteira do veículo com lateral do motorista" 
                    stepNumber="4" 
                    img={carPlateFrontImage}
                    orientation={[
                        "Veículo deve aparecer por completo.",
                        "Placa precisar estar visível."
                    ]}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "veiculo-dianteira-esquerda")}
                    handleNextStep={handleNextStep}
                    handleUpload={handleUpload}
                    
                    isAttach={false}
                    />
            </React.Fragment>
            case "5":
                return <React.Fragment>
                    <AttachmentCaptureScreen 
                    title="Foto da traseira do veículo com lateral do passageiro" 
                    stepNumber="5" 
                    img={carPlateBackImage}
                    orientation={[
                        "Veículo deve aparecer por completo.",
                        "Placa precisar estar visível."
                    ]}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "veiculo-traseira-direita")}
                    handleNextStep={handleNextStep}
                    handleUpload={handleUpload}
                    
                    isAttach={false}
                />
            </React.Fragment>
        case "6":
            return <React.Fragment>
                <AttachmentCaptureScreen 
                title="1º Foto da avaria do veículo" 
                stepNumber="6" 
                img={carDamege1Image}
                orientation={[
                    "Tire uma foto que apareça o dano ao veículo por completo."
                ]}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                details={apiPhotos.find(element => element.TipoFoto.Id === "dano-primeira-foto")}
                handleNextStep={handleNextStep}
                handleUpload={handleUpload}
                
                isAttach={false}
                />
            </React.Fragment>
        case "7":
            return <React.Fragment>
                <AttachmentCaptureScreen 
                    title="2º Foto da avaria do veículo" 
                    stepNumber="7" 
                    img={carDamege2Image}
                    orientation={[
                        "Tire uma foto que apareça o dano ao veículo agora por outro angulo."
                    ]}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    details={apiPhotos.find(element => element.TipoFoto.Id === "dano-segunda-foto")}
                    handleNextStep={handleNextStep}
                    handleUpload={handleUpload}
                    
                    isAttach={false}
                />
            </React.Fragment>
        // case "8":
        //     return <React.Fragment>
        //         <AttachmentCaptureScreen 
        //             title="Orçamento" 
        //         stepNumber="8" 
        //         img={reportCardImage}
        //         orientation={[
        //             "Posicione o documento dentro da área demarcada.",
        //             "Importante estar com todos os campos visíveis."
        //         ]}
        //         setSelectedFile={setSelectedFile}
        //         selectedFile={selectedFile}
        //         details={apiPhotos.find(element => element.TipoFoto.Id === "orcamento")}
        //         handleNextStep={handleNextStep}
        //             handleUpload={handleUpload}                
        //         />
        //     </React.Fragment>
        // default:
                return null
    }
}

    return (
        <Container>
            <h2>EVIDÊNCIA DO SINISTRO</h2>
            {isLoading ?
                <LoaderInline />:
                <Component />
            }

        </Container>
    )
}

export const Container = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    h2{
        color: #c1c1c1;
        font-size: 15px !important;
        font-weight: 700;
        line-height: 16.94px;
        letter-spacing: 2px;
        text-align: center;
        margin: 0;
        padding-top: 15px;
    }

`
