import React, { useEffect, useState } from "react"
import styled from "styled-components"
import alertIcon from "../../../assets/icons/alert_icon_yellow.svg"
import checkIcon from "../../../assets/icons/check_blue.svg"
import arrowDow from "../../../assets/icons/arrow_dow_black.svg"
import checkBoxActive from "../../../assets/icons/check-box-active.svg"
import arrowRight from "../../../assets/icons/arrow_right_white.svg"

export const ChangeOldPlan = ({ added, onChange, value }) => {
    const insurances = [
        {
            title: "Furto, roubo e perda total",
            text: "Pagamento 100% FIPE até R$ 30mil.",
            description: "Se o carro for roubado ou furtado, conte com a indenização integral FIPE do veículo até o valor contratado. Para carros em atividade profissional (Uber, Taxi, entregas e outros), a indenização é de 100% conforme o contrato. Já para PCD, veículos modificados, rebaixados, provenientes de leilão, a indenização é de 70% conforme o contrato, respeitando o limite contratado."
        },
        {
            title: "Assistência 24h",
            text: "Assistência 24h em todo território nacional.",
            description: "Assistência 24h completa via 0800 em todo o território nacional. Reboque 400km | Hotel - 5 pessoas | Chaveiro | Pane elétrica | Pane mecânica | Auxílio na falta de combustível | Táxi ou transporte alternativo | Troca de pneus | Recarga de bateria."
        },
        {
            title: "Carro reserva",
            text: "Carro reserva pelo período de 7 dias.",
        },

    ]

    const [plans, setPlans] = useState([])
    const isActive = plans[0]?.isSelect || plans[1]?.isSelect

    useEffect(() => {
        if (added) {
            setPlans([
                {
                    ...added[0],
                    title: "Colisão Completa + Terceiros + Seguro APP",
                    text: "Seguro contra batidas e fenômenos da natureza.",
                    isSelect: false,
                    isOpen: false,
                    description: "Pagamento integral ou parcial em caso de colisão. Cobertura para fenômenos da natureza (alagamento, granizo, queda de árvore e suas consequências). Lembrando que todas as coberturas respeitam o valor da Tabela FIPE limitado ao valor contratado.Cobertura de danos materiais e corporais contra terceiros, para acidente que envolvem outros veículos, reparando ou indenizando em até R$100.000,00 reais.APP Acidentes Pessoais a Passageiros com indenização de 10 mil em caso de morte ou invalidez, além de reembolso para despesas hospitalares até 3 mil reais."
                },
                {
                    ...added[1],
                    title: "Vidros Completo",
                    text: "Assistência vidros para troca ou reparo.",
                    isSelect: false,
                    isOpen: false,
                    description: "Assistência vidros com garantia para troca ou reparo dos vidros laterais, vidro traseiro, para-brisa, retrovisores, lanternas e faróis."
                }
            ])
        }
    }, [added])

    const toggleIsSelect = (index) => {

        setPlans((prevPlans) => {
            const newArray = prevPlans.map((plan, i) =>
                i === index ? { ...plan, isSelect: !plan.isSelect } : plan
            );
            handleChange(newArray)

            return newArray
        });

    };

    const toggleIsOpenDescription = (index) => {
        setPlans((prevPlans) => {
            return prevPlans.map((plan, i) =>
                i === index ? { ...plan, isOpen: !plan.isOpen } : plan
            );
        })
    };


    function handleChange(plan) {
        const included = plan.filter((element) => element.isSelect)

        const output = {
            added: included,
            notAdded: [],
            includedInPlan: included,
            excludedFromPlan: [],
            amountIncluded: included?.reduce((total, i) => {
                if ("$isReboque" in i) {
                    return total + i.$newPrice - i.$initialPrice;
                }
                return total + i.value;
            }, 0),
            amountExcluded: 0
        };

        onChange(output);
    }

    return (
        <Container>
            <CarInfo>
                <p>Veículo</p>
                <div>
                    <span>Modelo:</span> <span>Ford fiesta GLX 1.6 8v</span>
                </div>
                <div><span>Placa:</span> <span>IMM9X86</span></div>
            </CarInfo>
            <Alert>
                <img src={alertIcon} alt="" />
                <p>Aproveite mais benefícios e coberturas. Atualize agora para continuar
                    protegido com as melhores soluções que a Loovi tem a oferecer.</p>
            </Alert>
            <Insurance>
                <h5>Seguro Loovi</h5>
                <ul>
                    {
                        insurances.map(element => (
                            <InsuranceItem key={element.title}>
                                <details>
                                    <summary>
                                        <div>
                                            <img src={checkIcon} alt="" />
                                            <div>
                                                <p>{element.title}</p>
                                                <span>{element.text}</span>
                                            </div>
                                        </div>
                                        {element.description &&
                                            <img src={arrowDow} alt="" />
                                        }
                                    </summary>
                                    {element.description &&
                                        <TextInfo>
                                            {element.description}
                                        </TextInfo>
                                    }
                                </details>
                            </InsuranceItem>
                        ))
                    }
                </ul>
            </Insurance>

            <AdditionalPlans>
                <h5>Planos adicionais</h5>

                <ul>
                    {
                        plans.map((element, index) => (
                            <Plansitem isActive={element.isSelect} isOpen={element.isOpen} key={element.service_name}>
                                <div className="details">
                                    <summary >
                                        <div onClick={() => toggleIsSelect(index)}>
                                            <div className="check-box">
                                                {element.isSelect && <img src={checkBoxActive} alt="" />}
                                            </div>
                                            <div>
                                                <p>{element.title}</p>
                                                <span>{element.text}</span>
                                            </div>
                                        </div>

                                        <div onClick={() => toggleIsOpenDescription(index)}>
                                            <p>+ R$ {element.value}</p>
                                            <p>Mês</p>
                                            <img className="plan-arrow" src={arrowDow} alt="" />
                                        </div>
                                    </summary>
                                    {element.isOpen &&
                                        <TextInfo>
                                            {element.description}
                                        </TextInfo>
                                    }
                                </div>
                            </Plansitem>
                        ))
                    }
                </ul>
            </AdditionalPlans>

            <Price isActive={isActive}>
                <span>Valor do plano mensal:</span>

                <h3>{value}</h3>

                <button disabled={isActive} type="submit">Prosseguir {isActive && <img src={arrowRight} alt="" />}</button>
            </Price>
        </Container>)
}

const Container = styled.div`
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    gap: 24px;
`

const CarInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    div{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    p{
        font-size: 15px;
        font-weight: 700;
        line-height: 18.15px;
        letter-spacing: -0.02em;
        text-align: left;
        color:rgba(81, 81, 81, 1);
        
        margin-bottom: 6px;
    }

    span{
        font-size: 15px;
        font-weight: 400;
        line-height: 18.15px;
        text-align: left;
        color: rgba(161, 164, 171, 1);
        
    }
`

const Alert = styled.div`
    width: 100%;
    padding: 8px;
    background-color: rgba(255, 246, 218, 1);

    display: flex;
    align-items: start;
    gap: 8px;
    
    p{
        font-size: 12px !important;
        font-weight: 400;
        line-height: 14.52px;
    }

`

const Insurance = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5{
        font-size: 15px;
        font-weight: 700;
        line-height: 18.15px;
        letter-spacing: -0.02em;
        text-align: left;
        color: black;

    }

    ul{
        margin: 0;
        padding: 0;
    }

`

const InsuranceItem = styled.li`
    display: flex;

    details{
        width: 100%;
    }

    details[open] > summary> img:last-child{
        transform: rotate(180deg);
    }

    summary{
        display: flex;
        align-items: start;
        justify-content: space-between;

        width: 100%;
        padding-block: 12px;
    }

    summary > div{
        display: flex;
        align-items: start;
        gap: 12px;
    }

    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
    }

    span{
        font-size: 12px;
        font-weight: 400;
        color: rgba(161, 164, 171, 1);
    }   
`

const AdditionalPlans = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    h5{
        font-size: 15px;
        font-weight: 700;
        line-height: 18.15px;
        letter-spacing: -0.02em;
        text-align: left;
        color: black;

    }

    ul{
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        gap: 12px;
    }

`

const Plansitem = styled.li`
    list-style: none;

    
    .details{
        width: 100%;
    }
    
    summary{
        background-color: ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "white"};
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border: ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(242, 243, 248, 1)"} 2px solid;
        border-radius: 12px 12px ${({ isOpen }) => isOpen ? "0 0" : "12px 12px"};
        padding: 12px 10px;

        .check-box{
            min-width: 20px;
            height: 20px;
            border: ${({ isActive }) => isActive ? "none" : "1.67px solid rgba(81, 81, 81, 1)"};
            border-radius: 50%;

            img{
                width: 20px;
                height: 20px;
            }
        }
    }

    summary > div:first-child{
        display: flex;
        align-items: start;
        gap: 8px;
    }

    summary > div:last-child{
        min-width: 67px;
        text-align: end;
    }


    p{
        font-size: 14px !important;
        font-weight: 400;
        color:${({ isActive }) => isActive ? "white" : "black"};

    }

    span{
        font-size: 12px;
        color: ${({ isActive }) => isActive ? "white" : "rgba(161, 164, 171, 1)"};
    }

    .plan-arrow{
        filter: ${({ isActive }) => isActive ? "invert(100%)" : "invert(0%)"};
        transform: ${({ isOpen }) => isOpen ? "rotate(180deg)" : "rotate(0deg)"};
    }

    input{
        border-color: rgba(81, 81, 81, 1);
    }
`

const Price = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;

    span{
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        color: rgba(161, 164, 171, 1);
        margin-bottom: 10px;
    }

    h3{
        font-size: 36px;
        font-weight: 700;
        line-height: 43.56px;
        color: rgba(90, 120, 255, 1);
        margin: 0;
        text-align: center;
    }

    button{
        background-color: ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(213, 213, 213, 1)"};
        color: ${({ isActive }) => isActive ? "white" : "black"};
        border-radius: 4px;

        width: 100%;
        height: 48px;
        border: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
`
const TextInfo = styled.div`
    background-color: rgba(242, 242, 242, 1);
    padding: 10px;

    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: rgba(81, 81, 81, 1);



`